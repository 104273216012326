<template>
    <section id="Template">
        

        <div class="container">
        
        <!-- PAGE TITLE -->
            <page-title 
                title="Veritas - Winery" 
                subtitle="Comunicazione visiva per un ipotetica azienda vinicola italiana. Il suo nome è Veritas. <br><u>La sua mission</u>: vendere vini da diverse e rinomate località italiane e trasmettere visivamente la territorialità e italianità che differenzia tra di loro i vari vini." 
            />
        <!-- --------------------- -->

        <!-- CONCEPT -->
            <text-and-img title='Concept' imgType="" img=''>
                <p>Abbiamo cercato di trovare un'icona che potesse incarnare al meglio tutti i valori importanti che questa nuova azienda vuole trasmettere.</p>
                <p>Crediamo che l'idea di divinità dell'antica Roma possa infondere un forte senso di unicità, tipicità, qualità, territorialità e italianità.</p>
                <p>Questa scelta permette anche di dare personalità alle bottiglie, usando il nome proprio di una divinità si possono attribuire caratteristiche umane e raccontare in modo alternativo ed empatico le caratteristiche del vino che stiamo scegliendo.</p>
                <p>Oltre alla differenza di colori, sarà la territorialità e il luogo di produzione a fungere da elemento caratterizzante; l'etichetta sul fronte seguirà la silhouette del paesaggio in cui quel vino viene prodotto.</p>
                <p>Il tema del divino e la sagoma armoniosamente conciliati rappresentano rispettivamente la storia e la tradizione.</p>
            </text-and-img>
        <!-- --------------------- -->
        <!-- TONE OF VOICE -->
            <text-and-img title='Tone of Voice' imgType="cover" img='proj-veritas/moodboard.png'>
                <p>Le divinità, diverse per ogni tipologia di vino prodotto e coerenti con le loro caratteristiche, sono la vera anima della bottiglia.</p>
                <p><u>Il tone of voice della descrizione sul retro è in prima persona, come se fosse la divinità a parlare e raccontarsi</u>, elencandone gli aspetti in poche parole semplici, ma precise.</p>
                <p>La bottiglia, quindi, “prende vita” e con classe ed eleganza si presenta alla tavola e ai suoi consumatori che la ricorderanno come compagna di raffinati pranzi e cene.</p>
            </text-and-img>
        <!-- --------------------- -->
        <!-- Branding -->
            <text-and-img title='Branding' imgType="contain" img='proj-veritas/logo.png'>
                <p>Il logo esprime appieno l'essenza del marchio.</p>
                <p>Veritas, fa riferimento alla citazione "in vino veritas", storica e con un riferimento tipicamente latino che significa "nel vino è la verità". Il riferimento alla parola latina vuole esprimere l'appartenenza alla tradizione e alla storia che ci caratterizza.</p>
                <p>Abbiamo voluto coniugare i valori della storicità e del carattere italiano attraverso la rappresentazione di una colonna tipicamente romana con la modernità e la raffinatezza delle bollicine; valori che contraddistinguono l'intero progetto.</p>
            </text-and-img>
        <!-- --------------------- -->

        <!-- PHOTO SHOWREEL -->
            <Showreel
                title=""
                :imgs="[
                    //Find is a path and start automatically from imgs folder..
                    //if want find image in a folder inside imgs folder u need to specify which folder before img name
                    //(folder/name.jpg)
                    {
                        find: 'proj-veritas/mockup4.png',
                        imgType: 'contain',
                        alt: 'all veritas bottles', // <-- image alt text
                        text: ''
                    },
                    {
                        find: 'proj-veritas/logoDesign.png',
                        imgType: 'contain',
                        alt: '', // <-- image alt text
                        text: ''
                    },
                    {
                        find: 'proj-veritas/mockup1.png',
                        imgType: 'cover',
                        alt: 'veritas logo on paper sheet', // <-- image alt text
                        text: ''
                    },
                    {
                        find: 'proj-veritas/mockup2.png',
                        imgType: 'cover',
                        alt: 'veritas logo on wooden box', // <-- image alt text
                        text: ''
                    },
                    {
                        find: 'proj-veritas/mockup3.png',
                        imgType: 'contain',
                        alt: 'veritas bootle back', // <-- image alt text
                        text: '”Ti accompagnerò sempre durante pranzi e cene eleganti e curate, la mia delicatezza ti renderà partecipe della magia della natura” -- Giunone, Rosè.'
                    },
                ]"
            />
        <!-- --------------------- -->

        <div class="alone fit-cover" style="height: 450px;">
            <img src="@/assets/imgs/proj-veritas/thank-you.png" alt="">
        </div>

        <p class="focus-group">Aris Goi, Alessia Bompani, Ekaterina Kopstova, Viviana Ambrosi</p>

        </div>
    </section>
</template>

<script>
import PageTitle from '../../components/small/PageTitle.vue'
// import Gallery from '../../components/works-project/Gallery.vue'
import Showreel from '../../components/works-project/Showreel.vue'
import TextAndImg from '../../components/works-project/TextAndImg.vue'
// import ReadThe from '../../components/works-project/ReadThe.vue'

export default {
    name: 'Template',
    components: {
        PageTitle,
        Showreel,
        TextAndImg,
        // Gallery,
        // ReadThe,
    },
    metaInfo: {
        title: 'Veritas - Winery',
        meta: [{
            vmid: 'description',
            name: 'description',
            content: 'Ho creato la comunicazione visiva per un ipotetica azienda vinicola italiana. Il suo nome è Veritas. La sua mission: vendere vini da diverse e rinomate località italiane e trasmettere visivamente la territorialità e italianità che differenzia tra di loro i vari vini.',
        }],
    },
    data(){
        return{
            //
        }
    },
    methods: {
        //
    }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

@media screen and (max-width: 448px){
    .alone{
        width: 100% !important;
    }
}

</style>